export const formatNumber = (number: number) => {
    return Intl.NumberFormat(undefined, {}).format(number);
};

export function getPositiveInteger(value: string): number | null {
    if (!value) {
        return null;
    }

    // only allow digits
    if (!/^\d+$/.test(value)) {
        return null;
    }

    // the regex means this should be safe
    // eslint-disable-next-line @tritondigitaldev/unpredictable-number-conversion
    return parseFloat(value);
}

export function getPositiveIntegerUnsafe(value: string): number {
    const result = getPositiveInteger(value);

    if (result === null) {
        throw Error(value + ' is not positive integer');
    }

    return result;
}
